<template>
  <div>
    <base-header class="pb-6" style="background-color:#0bdeea !important;">
    </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col lg="2" />
        <b-col lg="8">
          <div class="card-wrapper">
            <!-- Input groups -->
            <card>
                <h3 class="mb20x">Listado de encuestas</h3>
               <b-table head-variant="light" bordered sortable="true" striped hover :items="items" :fields="fields"></b-table>
            </card>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  export default {
    name: 'form-elements',
    data() {
      return {
        encuestas: [],
        active: -1,
        alta: null,
        baja: null,
        fields: [
          {
            key: 'encuesta',
            sortable: true
          },
          {
            key: 'inicio',
            sortable: true
          },
          {
            key: 'fin',
            sortable: false
          },
          {
            key: 'estado',
            sortable: true
          }
        ],
        items: []
      }
    },

    mounted () {
      this.encuestas = JSON.parse(localStorage.getItem('encuestas') || "[]")
      // console.log(promociones)
      this.creaDatos()
    },

    methods: {
      filesChange(files) {
        this.inputs.file = files
      },

      selectEncuesta(index) {
        index == this.active ? this.active = -1 : this.active = index
        const separacion = this.encuestas[index].rango.split('to')
        // console.log(separacion)
        let altaFecha = separacion[0]
        altaFecha = altaFecha.split('-')
        this.alta = altaFecha[2] + '/' + altaFecha[1] + '/' + altaFecha[0]
        // this.baja = separacion[1]
        let bajaFecha = separacion[1]
        bajaFecha = bajaFecha.split('-')
        this.baja = bajaFecha[2] + '/' + bajaFecha[1] + '/' + bajaFecha[0]
      },

      creaDatos () {
        this.encuestas.map((item) => {
          const fechaPartida = item.rango.split('to')
          item.inicio = fechaPartida[0]
          item.fin = fechaPartida[1]
          item.encuesta = item.nombre
        })
        this.items = this.encuestas
      }
    }
  }
</script>
<style scoped>
table {
  margin: 0 auto;
}
.table-wrapper {
  width: 100%;
  overflow-x: auto;
  padding-bottom: 20px;
}

th{
  font-weight: bold !important;
  color:#000 !important;
  font-size:0.8rem !important;
  font-weight: bold;
  background-color:#11d5e3 !important;
}

.table-03 {
  width: 1000px;
  border-collapse: collapse;
  table-layout: fixed;
}

.table-03 th, .table-03 td {
  padding: 15px;
  border: 1px solid #ccc;
}

.table-03 th {
  background-color: #dedede;
}
</style>
