<template>

  <div>
    <base-header class="pb-6" style="background-color:#0bdeea !important;">
    </base-header>
    <b-container fluid class="mt--6">
      <b-row>

        <b-col lg="2" />
        <b-col lg="8">
          <div class="doti-content_container">
            <div class="card-wrapper">
            <!-- Input groups -->
            <card>
              <!-- Card header -->
              <h2 slot="header" class="mb-0">Creación de bonos</h2>
              <!-- Card body -->
              <form>
                <!-- Input groups with icon -->
                <b-row>
                  <b-col md="12">
                    <base-input v-model="nombre" prepend-icon="fas fa-user"
                      placeholder="Concepto"></base-input>
                  </b-col>
                  <b-col md="12">
                    <base-input v-model="relacion" prepend-icon="fas fa-user"
                      placeholder="Relación externa"></base-input>
                  </b-col>
                  <b-col md="12">
                    <base-input v-model="idDoti" prepend-icon="fas fa-user"
                      placeholder="Id Doti" type="number"></base-input>
                  </b-col>
                  <b-col md="12">
                    <base-input v-model="servicios" prepend-icon="fas fa-user"
                      placeholder="Servicios" type="number"></base-input>
                  </b-col>
                  
                  <b-col md="12" class="mt-4 text-center">
                    <base-button @click.prevent="guardaPromocion" type="primary" native-type="submit">Guardar bono
                    </base-button>
                  </b-col>
                </b-row>
              </form>
            </card>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>

  // import HtmlEditor from '@/components/Inputs/HtmlEditor'
  // import TagsInput from '@/components/Inputs/TagsInput'
  // import DropzoneFileUpload from '@/components/Inputs/DropzoneFileUpload'
  // import BaseSlider from '@/components/BaseSlider'

  export default {
    components: {
    },
    data() {
      return {
        selectSexo: [{
            label: 'Hombre',
            value: 'hombre'
          },
          {
            label: 'Mujer',
            value: 'mujer'
          },
        ],
        selectPostal: [
          {

            label: 'Local',
            value: 'local'
          },
          {
            label: 'Provincia',
            value: 'provincia'
          },
          {
            label: 'Nacional',
            value: 'nacional'
          }
        ],
        selectPerfil: [{
            label: 'Generación silenciosa',
            value: 'silenciosa'
          },
          {
            label: 'Baby boomers',
            value: 'boomers'
          },
          {
            label: 'Generación X',
            value: 'x'
          },
          {
            label: 'Millennials',
            value: 'millenials'
          },
          {
            label: 'Generación Z',
            value: 'z'
          }
        ],
        selectsPostal: [],
        selectsPerfil: [],
        selectsSexo: '',
        rangoPromocion: '',
        nombrePromocion: '',
        sexo: ''

      }
    },

    methods: {
      guardaPromocion() {
        const data = {
          nombre: this.nombrePromocion,
          cod_postal: this.selectPostal,
          perfiles: this.selectPerfil,
          rango: this.rangoPromocion,
          sexo: this.selectsSexo,
        }
        var promociones = JSON.parse(localStorage.getItem('promociones') || "[]")
        promociones.push(data)
        localStorage.setItem("promociones", JSON.stringify(promociones))
      }
    }
  }
</script>
<style scoped>
.W100{
  width:100%;
}
</style>