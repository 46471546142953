<template>

  <div>
    <base-header class="pb-6" style="background-color:#0bdeea !important;">
    </base-header>
    <b-container fluid class="mt--6">
      <b-row>

        <b-col lg="2" />
        <b-col lg="8">
          <div class="doti-content_container">
            <div class="card-wrapper">
            <!-- Input groups -->
            <card>
              <!-- Card header -->
              <h2 slot="header" class="mb-0">Creación de promociones</h2>
              <!-- Card body -->
              <form>
                <!-- Input groups with icon -->
                <b-row>
                  <b-col md="12">
                    <base-input v-model="nombrePromocion" prepend-icon="fas fa-user"
                      placeholder="Nombre de la promocion"></base-input>
                  </b-col>
                  <b-col md="6">
                    <base-input label="Tipo promoción">
                      <el-select v-model="selectsPostal" filterable placeholder="">
                        <el-option v-for="option in selectPostal" :key="option.label" :label="option.label"
                          :value="option.value">
                        </el-option>
                      </el-select>
                    </base-input>
                  </b-col>
                  <b-col md="6">
                    <base-input label="Perfil del cliente">
                      <el-select v-model="selectsPerfil" multiple filterable placeholder="">
                        <el-option v-for="option in selectPerfil" :key="option.label" :label="option.label"
                          :value="option.value">
                        </el-option>
                      </el-select>
                    </base-input>
                  </b-col>
                  <b-col md="6">
                    <base-input label="Sexo">
                      <el-select v-model="selectsSexo" multiple filterable placeholder="">
                        <el-option v-for="option in selectSexo" :key="option.label" :label="option.label"
                          :value="option.value">
                        </el-option>
                      </el-select>
                    </base-input>
                  </b-col>
                  <b-col md="6">
                    <!-- <base-input label="Rango de fechas de la encuesta">
                      <flat-picker slot-scope="{focus, blur}" @on-open="focus" @on-close="blur"
                        :config="{mode: 'range',}" class="form-control datepicker" v-model="rangoPromocion"
                        style="text-align:center;">
                      </flat-picker>
                    </base-input>
                     <base-input label="Rango de fechas de la encuesta">
                      <flat-picker slot-scope="{focus, blur}" @on-open="focus" @on-close="blur"
                        :config="{mode: 'range',}" class="form-control datepicker" v-model="rangoPromocion"
                        style="text-align:center;">
                      </flat-picker>
                    </base-input> -->
                     <base-input label="Rango de fechas de la promoción">
                        <flat-picker slot-scope="{focus, blur}"
                                     @on-open="focus"
                                     @on-close="blur"
                                     :config="{mode: 'range',}"
                                     class="form-control datepicker"
                                     v-model="rangoPromocion"
                                     style="text-align:center;">
                        </flat-picker>
                      </base-input>
                  </b-col>
                  <b-col md="12">
                    <label for="" class="d-block">Descripción</label>
                    <textarea name="" id="" rows="6" class="W100"></textarea>
                  </b-col>
                  <b-col md="12" class="mt-4 text-center">
                    <base-button @click.prevent="guardaPromocion" type="primary" native-type="submit">Guardar promocion
                    </base-button>
                  </b-col>
                </b-row>
              </form>
            </card>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import {
    Select,
    Option
  } from 'element-ui'
  // import flatPicker from "vue-flatpickr-component";
  import flatPicker from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";

  // import HtmlEditor from '@/components/Inputs/HtmlEditor'
  // import TagsInput from '@/components/Inputs/TagsInput'
  // import DropzoneFileUpload from '@/components/Inputs/DropzoneFileUpload'
  // import BaseSlider from '@/components/BaseSlider'

  export default {
    name: 'form-components',
    components: {
      // HtmlEditor,
      // TagsInput,
      // DropzoneFileUpload,
      // BaseSlider,
      flatPicker,
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data() {
      return {
        selectSexo: [{
            label: 'Hombre',
            value: 'hombre'
          },
          {
            label: 'Mujer',
            value: 'mujer'
          },
        ],
        selectPostal: [
          {

            label: 'Local',
            value: 'local'
          },
          {
            label: 'Provincia',
            value: 'provincia'
          },
          {
            label: 'Nacional',
            value: 'nacional'
          }
        ],
        selectPerfil: [{
            label: 'Generación silenciosa',
            value: 'silenciosa'
          },
          {
            label: 'Baby boomers',
            value: 'boomers'
          },
          {
            label: 'Generación X',
            value: 'x'
          },
          {
            label: 'Millennials',
            value: 'millenials'
          },
          {
            label: 'Generación Z',
            value: 'z'
          }
        ],
        selectsPostal: [],
        selectsPerfil: [],
        selectsSexo: '',
        rangoPromocion: '',
        nombrePromocion: '',
        sexo: ''

      }
    },

    methods: {
      guardaPromocion() {
        const data = {
          nombre: this.nombrePromocion,
          cod_postal: this.selectPostal,
          perfiles: this.selectPerfil,
          rango: this.rangoPromocion,
          sexo: this.selectsSexo,
        }
        var promociones = JSON.parse(localStorage.getItem('promociones') || "[]")
        promociones.push(data)
        localStorage.setItem("promociones", JSON.stringify(promociones))
      }
    }
  }
</script>
<style scoped>
.W100{
  width:100%;
}
</style>