<template>
  <div>
    <base-header class="pb-6" style="background-color:#0bdeea !important;">
    </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col lg="2" />
        <b-col lg="8">
          <div class="card-wrapper">
            <!-- Input groups -->
            <card>
              <!-- Card header -->
              <h3 slot="header" class="mb-0">Creación de encuestas</h3>
              <!-- Card body -->
              <form>
                <!-- Input groups with icon -->
                <b-row>
                  <b-col md="12">
                    <base-input v-model="nombreEncuesta" prepend-icon="fas fa-user" placeholder="Nombre de la encuesta"></base-input>
                  </b-col>
                  <b-col md="12">
                    <base-input v-model="primeraPregunta" prepend-icon="fas fa-question" placeholder="Primera pregunta"></base-input>
                  </b-col>
                  <b-col md="6">
                    <base-input v-model="fprimeraRespuesta" prepend-icon="fa fa-comment" placeholder="Primera respuesta posible"></base-input>
                  </b-col>
                  <b-col md="6">
                    <base-input v-model="fsegundaRespuesta" prepend-icon="fa fa-comment" placeholder="Segunda respuesta posible"></base-input>
                  </b-col>
                  <b-col md="12">
                    <base-input v-model="segundaPregunta" prepend-icon="fa fa-question" placeholder="Segunda pregunta"></base-input>
                  </b-col>
                  <b-col md="6">
                    <base-input v-model="sprimeraRespuesta" prepend-icon="fa fa-comment" placeholder="Primera respuesta posible"></base-input>
                  </b-col>
                  <b-col md="6">
                    <base-input v-model="ssegundaRespuesta" prepend-icon="fa-solid fa-comment" placeholder="Segunda respuesta posible"></base-input>
                  </b-col>
                   <b-col md="4">
                    <base-input label="Preguntas">
                      <el-select v-model="pregunta"
                                 filterable
                                 placeholder="">
                        <el-option v-for="option in preguntas"
                                   :key="option.label"
                                   :label="option.label"
                                   :value="option.value">
                        </el-option>
                      </el-select>
                    </base-input>
                  </b-col>
                  <b-col md="4">
                    <base-input label="Respuestas">
                      <el-select v-model="respuesta"
                                 filterable
                                 placeholder="">
                        <el-option v-for="option in respuestas"
                                   :key="option.label"
                                   :label="option.label"
                                   :value="option.value">
                        </el-option>
                      </el-select>
                    </base-input>
                  </b-col>
                  <b-col md="4" align-self="center">
                    <base-button @click.prevent="agredaProduct" type="primary" native-type="submit">Agregar</base-button>
                  </b-col>
                  <b-col md="12">
                    <b-table v-if="items.length !== 0" :items="items" :fields="fields" striped responsive="sm">
                    </b-table>
                  </b-col>
                  <b-col md="6">
                    <base-input label="Código postal">
                      <el-select v-model="selectsPostal"
                                 multiple
                                 filterable
                                 placeholder="">
                        <el-option v-for="option in selectPostal"
                                   :key="option.label"
                                   :label="option.label"
                                   :value="option.value">
                        </el-option>
                      </el-select>
                    </base-input>
                  </b-col>
                  <b-col md="6">
                    <base-input label="Perfil del cliente">
                      <el-select v-model="selectsPerfil"
                                 multiple
                                 filterable
                                 placeholder="">
                        <el-option v-for="option in selectPerfil"
                                   :key="option.label"
                                   :label="option.label"
                                   :value="option.value">
                        </el-option>
                      </el-select>
                    </base-input>
                  </b-col>
                  <b-col md="6">
                    <base-input label="Sexo">
                      <el-select v-model="selectsSexo" filterable multiple
                                 placeholder="">
                        <el-option v-for="option in selectSexo"
                                   :key="option.label"
                                   :label="option.label"
                                   :value="option.value">
                        </el-option>
                      </el-select>
                    </base-input>
                  </b-col>
                    <b-col md="6">
                      <base-input label="Rango de fechas de la encuesta">
                        <flat-picker slot-scope="{focus, blur}"
                                     @on-open="focus"
                                     @on-close="blur"
                                     :config="{mode: 'range',}"
                                     class="form-control datepicker"
                                     v-model="rangoEncuesta"
                                     style="text-align:center;">
                        </flat-picker>
                      </base-input>
                    </b-col>
                    <b-col md="3" />
                    <b-col md="6" style="text-align:center;">
                        <base-button @click.prevent="guardaEncuesta" type="primary" native-type="submit">Guardar encuesta</base-button>
                    </b-col>
                </b-row>
              </form>
            </card>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import { Select, Option } from 'element-ui'
  import flatPicker from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";

  import HtmlEditor from '@/components/Inputs/HtmlEditor'
  import TagsInput from '@/components/Inputs/TagsInput'
  import DropzoneFileUpload from '@/components/Inputs/DropzoneFileUpload'
  import BaseSlider from '@/components/BaseSlider'

  export default {
    name: 'form-components',
    components: {
      // HtmlEditor,
      // TagsInput,
      // DropzoneFileUpload,
      // BaseSlider,
      flatPicker,
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data() {
      return {
        selectSexo: [
          {
            label: 'Hombre',
            value: 'hombre'
          },
          {
            label: 'Mujer',
            value: 'mujer'
          },
        ],
        selectPostal: [
          {

            label: '28030',
            value: 'uno'
          },
          {
            label: '28040',
            value: 'dos'
          },
          {
            label: '28036',
            value: 'tres'
          },
          {
            label: '28020',
            value: 'cuatro'
          }
        ],
        preguntas: [
          {

            label: 'Primera pregunta',
            value: 'uno'
          },
          {
            label: 'Segunda pregunta',
            value: 'dos'
          },
          {
            label: 'Tercera pregunta',
            value: 'tres'
          },
          {
            label: 'Cuarta pregunta',
            value: 'cuatro'
          }
        ],
        respuestas: [
          {

            label: 'Primera respuesta',
            value: 'uno'
          },
          {
            label: 'Segunda respuesta',
            value: 'dos'
          },
          {
            label: 'Tercera respuesta',
            value: 'tres'
          },
          {
            label: 'Cuarta respuesta',
            value: 'cuatro'
          }
        ],
        selectPerfil: [
          {
            label: 'Generación silenciosa',
            value: 'silenciosa'
          },
          {
            label: 'Baby boomers',
            value: 'boomers'
          },
          {
            label: 'Generación X',
            value: 'x'
          },
          {
            label: 'Millennials',
            value: 'millenials'
          },
          {
            label: 'Generación Z',
            value: 'z'
          }
        ],
        selectsPostal: [],
        selectsPerfil: [],
        selectsSexo: '',
        rangoEncuesta: '',
        nombreEncuesta: '',
        primeraPregunta: '',
        segundaPregunta: '',
        fprimeraRespuesta: '',
        sprimeraRespuesta: '',
        fsegundaRespuesta: '',
        ssegundaRespuesta: '',
        sexo: '',
        respuesta: '',
        pregunta: '',
        items: [],
        fields: [
          { key: 'pregunta', sortable: false },
          { key: 'respuesta', sortable: false }
        ],
      }
    },

    methods: {
      guardaEncuesta() {
        const data = {
            nombre: this.nombreEncuesta,
            primeraPregunta: {
                pregunta: this.primeraPregunta,
                respuesta_1: this.fprimeraRespuesta,
                respuesta_2: this.fsegundaRespuesta
            },
            segundaPregunta: {
                pregunta: this.segundaPregunta,
                respuesta_1: this.sprimeraRespuesta,
                respuesta_2: this.ssegundaRespuesta
            },
            cod_postal: this.selectPostal,
            perfiles: this.selectPerfil,
            rango: this.rangoEncuesta,
            sexo: this.selectsSexo,
        }
        var encuestas = JSON.parse(localStorage.getItem('encuestas') || "[]")
        encuestas.push(data)
        localStorage.setItem("encuestas", JSON.stringify(encuestas))
      },

      agredaProduct () {
        this.items.push({
          respuesta: this.respuesta,
          pregunta: this.pregunta
        })
        this.respuesta = ''
        this.pregunta = ''
      },
    }
  }
</script>
